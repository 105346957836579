import axios from 'axios'

const axiosApi = axios.create({
  // baseURL: 'http://localhost:3090/' // LOCAL
  baseURL: 'https://lucatti-web.herokuapp.com/' // PROD
});

axiosApi.interceptors.request.use(config => {
  const token = localStorage.getItem('@avalon-b2b-token');
  if (token) {
    config.headers['x-token'] = token
  }

  config.headers['company'] = 'avalon';

  return config
})

export default axiosApi