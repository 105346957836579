import Vue from 'vue'
import Vuex from 'vuex'
import UserService from "@/services/user";

// import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
//   modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  state: {
    status: '',
    token: localStorage.getItem('@avalon-b2b-token') || '',
    user: {},
    order: {},
    cartQuantity: 0,
    cartValue: 0,
    customerPrice: {},
    activity: {},
  },
  mutations: {
    ['AUTH_REQUEST']: (state) => {
      state.status = 'loading'
    },
    ['AUTH_SUCCESS']: (state, token) => {
      state.status = 'success'
      state.token = token
    },
    ['AUTH_ERROR']: (state) => {
      state.status = 'error'
    },
    ['AUTH_LOGOUT']: (state) => {
      state.status = ''
      state.token = ''
      state.order = {}
      state.cartQuantity = 0;
    },
    ['USER_DATA']: (state, data) => {
      state.user = data
    },
    ['START_ORDER']: (state, data) => {
      state.order = {
        companyId: data.companyId,
        customerId: data.customerId,
      }
    },
    ['STOP_ORDER']: (state) => {
      state.order = {}
      state.cartQuantity = 0;
    },
    ['SAVE_ORDER']: (state, data) => {
      state.order = data;

      if (data.cart) {
        const validItems = data.cart.items.filter(item => item.quantity > 0)
        state.cartQuantity = validItems.length;
        state.cartValue = data.cart.total || 0;
      } else {
        state.cartQuantity = 0;
        state.cartValue = 0;
      }
    },
    ['OPEN_ORDER']: (state, data) => {
      state.order = data;
    },
    ['CUSTOMER_PRICE']: (state, data) => {
      state.customerPrice = {
        companyId: data.companyId,
        customerId: data.customerId,
      }
    },
    ['NEW_ACTIVITY']: (state, data) => {
      state.activity = {
        customerName: data.customerName,
        customerId: data.customerId,
      }
    },
  },
  actions: {
    ['AUTH_REQUEST']: ({commit}, data) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_REQUEST')
        UserService.login(data.user, data.password)
          .then(res => {
            const token = res.data.token
            localStorage.setItem('@avalon-b2b-token', token) // store the token in localstorage

            commit('AUTH_SUCCESS', token)
            commit('STOP_ORDER');
            // you have your token, now log in your user :)
            resolve(res)
          })
        .catch(err => {
          commit('AUTH_ERROR', err)
          localStorage.removeItem('@avalon-b2b-token') // if the request fails, remove any possible user token if possible
          reject(err)
        })
      })
    },
    ['AUTH_LOGOUT']: ({commit}) => {
      return new Promise((resolve) => {
        localStorage.removeItem('@avalon-b2b-token') // clear your user's token from localstorage
        commit('USER_DATA', {});
        commit('AUTH_LOGOUT')
        resolve()
      })
    },
    ['USER_DATA']: ({commit, state}) => {
      return new Promise((resolve, reject) => {
        if (state.user.user) {
          return resolve(state.user);
        }

        UserService.getData()
          .then(res => {
            commit('USER_DATA', res.data);

            resolve(res.data)
          })
        .catch(err => {
          reject(err)
        })
      })
    },
    ['START_ORDER']: ({ commit }, data) => {
      commit('START_ORDER', data);
    },
    ['STOP_ORDER']: ({ commit }) => {
      commit('STOP_ORDER');
    },
    ['SAVE_ORDER']: ({ commit }, data) => {
      commit('SAVE_ORDER', data);
    },
    ['OPEN_ORDER']: ({ commit }, data) => {
      commit('OPEN_ORDER', data);
    },
    ['CUSTOMER_PRICE']: ({ commit }, data) => {
      commit('CUSTOMER_PRICE', data);
    },
    ['NEW_ACTIVITY']: ({ commit }, data) => {
      commit('NEW_ACTIVITY', data);
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    userData: state => state.user,
    companyData: state => state.order.companyId,
    orderData: state => state.order,
    orderStarted: state => state.order.customerId ? true : false,
    cartQuantity: state => state.cartQuantity,
    cartValue: state => state.cartValue,
    customerPrice: state => state.customerPrice,
    newActivity: state => state.activity,
  },
  strict: process.env.NODE_ENV !== 'production',
})

export default store

